import React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';

import history from "./config/history";

import ErrorBoundary from "./components/UI/ErrorBoundary";
import ScrollToTop from './components/UI/ScrollToTop';
import LocaleProvider from "./LocaleProvider";
import Root from './components/Root';
import store from './redux';

const App = () => (
   <Provider store={store}>
      <ErrorBoundary>
         <LocaleProvider>
            <ConnectedRouter history={history}>
               <ScrollToTop>
                  <Root/>
               </ScrollToTop>
            </ConnectedRouter>
         </LocaleProvider>
      </ErrorBoundary>
   </Provider>
);

export default App;
