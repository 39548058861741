import React from 'react';
import {Route, Switch} from 'react-router-dom';

import PrivateRoute from './PrivateRouter/PrivateRouter'
import HomePage from './Routes/HomePage';
import InformationPage from './Routes/InformationPage';
import AboutPage from './Routes/AboutPage';

const Root = () => (
   <Switch>
      <PrivateRoute exact path="/" component={HomePage}/>
      <Route path="/information" component={InformationPage}/>
      <Route path="/about-project" component={AboutPage}/>
      <Route render={() => <h1 className="not-found">404 page not found</h1>}/>
   </Switch>
);

export default Root;
