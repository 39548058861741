import React from 'react';
import propTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {connect} from "react-redux";

import {openModal} from "../../ducks/ModalTry";

import {group4} from '../../assets/img';

const Pictures = ({openModal}) => (
   <section className="picture-section">
      <div className="wrapper">
         <div className="picture-box flex j-sb flex-align-center" data-aos="fade-right" data-aos-duration="600">
            <div className="picture-box__img img-position-adaptive">
               <img className="full-width" src={group4} alt="meet.kz"/>
            </div>

            <div className="picture-box__info info-position-r">
               <h2 className="picture-box__title text-title"><FormattedMessage id="pictureTitle"/></h2>
               <p className="picture-box__desc text-desc"><FormattedMessage id="pictureDesc"/></p>
               <button className="picture-box__btn main-btn transition" onClick={openModal}>
                  <FormattedMessage id="btn_try"/>
               </button>
            </div>
         </div>
      </div>
   </section>
);

Pictures.propTypes = {
   openModal: propTypes.func.isRequired
};

export default connect(null, {openModal})(Pictures);
