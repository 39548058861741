import React from 'react';
import propTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {connect} from "react-redux";

import {openModal} from "../../ducks/ModalTry";

import ChangeLang from '../UI/ChangeLang';

import {logo, googlePlay, group3} from '../../assets/img';

const Header = ({openModal}) => (
   <header>
      <div className="wrapper">
         <div className="header-logo flex j-sb flex-align-center">
            <div className="header-language flex flex-align-center">
               <div className="header-logo__img">
                  <img className="full-width" src={logo} alt="logo"/>
               </div>

               <ChangeLang/>
            </div>

            <div className="header-download">
               <a
                  className="flex flex-align-center text-decor-none transition"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://play.google.com/store/apps/details?id=kz.meet"
               >
                  <div className="header-download__icon">
                     <img className="full-width" src={googlePlay} alt="google play"/>
                  </div>
                  <p className="header-download__desc">
                     <FormattedMessage id="downloadByGooglePlay"/>
                     <span>
                        <FormattedMessage id="googlePlay"/>
                     </span>
                  </p>
               </a>
            </div>
         </div>

         <div className="header-body flex j-sb">
            <div className="header-info info-position-l">
               <h1 className="header-info__title text-title">
                  <FormattedMessage id="headerTitle"/>
               </h1>

               <p className="header-info__desc text-desc">
                  <FormattedMessage id="headerDesc"/>
               </p>

               <button className="responsive-header-info__call transition">
                  <a className="text-decor-none" href="tel:7889">
                     <i className="fa fa-phone" aria-hidden="true"/>
                     <FormattedMessage id="headerCall"/>
                  </a>
               </button>

               <button className="header-info__sign-in main-btn transition" onClick={openModal}>
                  <FormattedMessage id="btn_enter"/>
               </button>
            </div>
            <div className="header-img img-position-adaptive">
               <img className="full-width" src={group3} alt="meet"/>
            </div>
         </div>
      </div>

      <div className="header-wave">
         <svg id="Layer_1" viewBox="0 0 1416.99 174.01">
            <path
               className="cls-1" d="M0,280.8S283.66,59,608.94,163.56s437.93,150.57,808,10.34V309.54H0V280.8Z"
               transform="translate(0 -135.53)"
            />
         </svg>
      </div>
   </header>
);

Header.propTypes = {
   openModal: propTypes.func.isRequired,
};

export default connect(null, {openModal})(Header);
