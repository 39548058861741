import {combineReducers, createStore, applyMiddleware} from "redux";
import {routerMiddleware, connectRouter} from "connected-react-router";
import {composeWithDevTools} from 'redux-devtools-extension';
import thunkMiddleware from "redux-thunk";
import history from "../config/history";

// Reducers
import localeReducer, {moduleName as localeModule} from '../ducks/Locale';
import modalTryReducer, {moduleName as modalTryModule} from '../ducks/ModalTry';
import authReducer, {moduleName as authModule} from '../ducks/Auth';
import chatReducer, {moduleName as chatModule} from "../ducks/Chat";

const reducers = history => combineReducers({
   router: connectRouter(history),
   [localeModule]: localeReducer,
   [modalTryModule]: modalTryReducer,
   [authModule]: authReducer,
   [chatModule]: chatReducer
});

// Store
const enhancer = applyMiddleware(
   thunkMiddleware,
   routerMiddleware(history)
);
const store = createStore(reducers(history), composeWithDevTools(enhancer));

export default store;
