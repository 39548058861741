import React from 'react';
import propTypes from "prop-types";
import {connect} from 'react-redux';
import {FormattedMessage} from "react-intl";

import {moduleName as chatModule, wsLogout} from "../../../ducks/Chat";

const ChatLogout = ({userData, wsLogout}) => userData ? (
   <div className="chat-logout">
      <button className="chat-logout-btn main-btn transition" onClick={wsLogout}>
         <FormattedMessage id="chatLogout"/>
      </button>
   </div>
) : null;

ChatLogout.propTypes = {
   userData: propTypes.object,
};

export default connect(state => ({
   userData: state[chatModule].userData,
}), {
   wsLogout
})(ChatLogout);
