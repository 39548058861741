import React from 'react';

import ModalTry from "../ModalTry/ModalTry";
import Header from "../Sections/Header";
import Chat from "../Sections/Chat/Chat";
import Pictures from "../Sections/Pictures";
import Find from "../Sections/Find";
import Communicate from "../Sections/Communicate";
import Footer from "../Sections/Footer";

const HomePage = () => (
   <div className="home-page-container">
      <ModalTry/>

      <Header/>
      <Chat/>
      <Pictures/>
      <Find/>
      <Communicate/>
      <Footer/>
   </div>
);

export default HomePage;
