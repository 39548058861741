import React from 'react';
import propTypes from "prop-types";
import {connect} from 'react-redux';

import ChatMessages from "./ChatMessages";
import ChatSendMessage from "./ChatSendMessage";

import {moduleName as chatModule} from "../../../ducks/Chat";

const ChatBox = ({userData}) => (
   <div className={`chat-box info-position-r ${userData && 'chat-success-width'}`}>
      <ChatMessages/>
      <ChatSendMessage/>
   </div>
);

ChatBox.propTypes = {
   userData: propTypes.object,
};


export default connect(state => ({
   userData: state[chatModule].userData,
}))(ChatBox);
