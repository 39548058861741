import React, {Component} from 'react';
import propTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedMessage} from "react-intl";

import {
   loadLastMessages, loadNewMessage,

   moduleName as chatModule
} from '../../../ducks/Chat';

import {noAvatar} from '../../../assets/img'

class ChatMessages extends Component {
   static propTypes = {
      oMessages: propTypes.arrayOf(propTypes.object),
      userData: propTypes.object,
      typingUid: propTypes.object,

      loadLastMessages: propTypes.func.isRequired,
      loadNewMessage: propTypes.func.isRequired
   };

   componentDidMount() {
      const chatBox = document.getElementById('chat-box-messages');
      chatBox.scrollTop = chatBox.scrollHeight - chatBox.clientHeight;

      this.props.loadLastMessages();
      this.props.loadNewMessage();
   }

   componentDidUpdate(prevProps) {
      if (this.props.typingUid === prevProps.typingUid) {
         const chatBox = document.getElementById('chat-box-messages');
         chatBox.scrollTop = chatBox.scrollHeight - chatBox.clientHeight;
      }
   }

   msConvert = ms => {
      const date = new Date(ms);
      const hours = date.getHours().toString().length === 1 ? `0${date.getHours()}` : date.getHours();
      const minutes = date.getMinutes().toString().length === 1 ? `0${date.getMinutes()}` : date.getMinutes();

      return `${hours}:${minutes}`;
   };

   render() {
      const {oMessages, userData, typingUid} = this.props;

      const UserTyping = () =>
         typingUid && typingUid.eventType === 'start_typing' &&
         <p className="chat-typing text-desc">
            <FormattedMessage id="chatTyping"/>
         </p>;

      return (
         <div id="chat-box-messages" className="chat-box-messages">
            {oMessages && oMessages.map(message => (
               <div
                  className={`chat-box-messages__item ${userData && userData.uid === message.user.uid && 'chat-box-my-message'} flex flex-align-bottom`}
                  key={message.id}
               >
                  <p className="chat-box-date my-message">{this.msConvert(message.created)}</p>

                  <div className="chat-box-avatar other-message">
                     <img src={message.user.avatar || noAvatar} alt="avatar"/>
                  </div>

                  <div className="chat-box-message">
                     <p className="responsive-example-box-message__text">
                        {message.message}

                        <span className="chat-box-message__author">{message.user.nickname}</span>
                     </p>
                  </div>
                  <p className="chat-box-date other-message">{this.msConvert(message.created)}</p>

                  <div className="chat-box-avatar my-message">
                     <img src={message.user.avatar || noAvatar} alt="avatar"/>
                  </div>
               </div>
            ))}

            <div className="chat-typing-box">
               <UserTyping/>
            </div>
         </div>
      )
   }
}

export default connect(state => ({
   userData: state[chatModule].userData,
   typingUid: state[chatModule].typingUid,
   oMessages: state[chatModule].oMessages,
}), {
   loadLastMessages,
   loadNewMessage
})(ChatMessages);
