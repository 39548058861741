import React, {Fragment} from 'react';
import propTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {connect} from 'react-redux';

import {changeLang, moduleName as localeModule} from '../../ducks/Locale';

const ChangeLang = ({changeLang, lang, isFooter}) => (
   !isFooter ? (
      <Fragment>
         <div className="uk-inline header-language__box">
            <button className="header-language__box__btn">
               <FormattedMessage id={lang === 'ru' ? 'languagePanelRu' : 'languagePanelKz'}/>
            </button>
            <div className="header-language-dropdown header-language-dropdown-item" uk-dropdown="mode: click">
               <ul>
                  <li onClick={() => changeLang(lang === 'ru' ? 'kz' : 'ru')}>
                     <FormattedMessage id={lang !== 'ru' ? 'languagePanelRu' : 'languagePanelKz'}/>
                  </li>
               </ul>
            </div>
         </div>
         <div className="uk-inline responsive-header-language">
            <button className="header-language__box__btn">
               <FormattedMessage id={lang === 'ru' ? 'languageShortRu' : 'languageShortKz'}/>
            </button>
            <div className="header-language-dropdown header-language-dropdown-item" uk-dropdown="mode: click">
               <ul>
                  <li onClick={() => changeLang(lang === 'ru' ? 'kz' : 'ru')}>
                     <FormattedMessage id={lang !== 'ru' ? 'languageShortRu' : 'languageShortKz'}/>
                  </li>
               </ul>
            </div>
         </div>
      </Fragment>
   ) : (
      <div className="uk-inline footer-box-lang">
         <button className="footer-change-lang">
            <FormattedMessage id="footerLanguage"/>
         </button>
         <div
            className="header-language-dropdown header-language-dropdown-item"
            uk-dropdown="mode: click; pos: top-left"
         >
            <ul>
               <li onClick={() => changeLang('ru')}>
                  <FormattedMessage id="languagePanelRu"/>
               </li>
               <li onClick={() => changeLang('kz')}>
                  <FormattedMessage id="languagePanelKz"/>
               </li>
            </ul>
         </div>
      </div>
   )
);

ChangeLang.propTypes = {
   changeLang: propTypes.func.isRequired,
   isFooter: propTypes.bool,
   lang: propTypes.string
};

export default connect(state => ({
   lang: state[localeModule].lang
}), {changeLang})(ChangeLang);
