import React from 'react';
import {FormattedMessage} from "react-intl";

import {group5} from '../../assets/img';
import Stock from "./Stock";

const Find = () => (
   <section className="find-section">
      <div className="wrapper">
         <div className="find-box flex j-sb flex-align-center" data-aos="fade-left" data-aos-duration="600">
            <div className="find-box__info info-position-l">
               <h2 className="find-box__title text-title">
                  <FormattedMessage id="findTitle"/>
               </h2>

               <p className="find-box__desc text-desc">
                  <FormattedMessage id="findDesc"/>
               </p>
            </div>

            <div className="find-box__img img-position-adaptive">
               <img className="full-width" src={group5} alt="find"/>
            </div>
         </div>
      </div>

      <Stock/>
   </section>
);

export default Find;
