import io from "socket.io-client";
import axios from 'axios';

export const socket = io(process.env.REACT_APP_WS_SERVER);

const instance = axios.create({
   baseURL: process.env.REACT_APP_API_SERVER,
   headers: {
      'Content-Type': 'application/json',
   }
});

instance.defaults.timeout = 13000;

export const Auth = {
   checkNumber: data => instance.post('/user/send-password', {data}),
   login: data => instance.post('/user/login', {data}),
   wsLogin: data => instance.post('/ws/auth', {data}),
   resetPassword: data => instance.post('/user/reset-password', {data})
};

export const Information = {
	getOfertaInfo: lang => instance.get(`/settings/oferta/web/${lang}`),
	getAboutServiceInfo: lang => instance.get(`/settings/about_service/web/ru${lang}`),
};