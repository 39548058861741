import React from 'react';
import {FormattedMessage} from "react-intl";

const ErrorIndicator = ({errorMessage}) => {
   console.log(errorMessage.message);

   return (
      <h1 className="error-indicator">
         <FormattedMessage id="errorIndicator"/>
      </h1>
   )
};

export default ErrorIndicator;
