import React from 'react';
import propTypes from "prop-types";
import {connect} from 'react-redux';

import ChatSignIn from "./ChatSignIn";
import ChatLogout from "./ChatLogout";
import ChatBox from "./ChatBox";

import {moduleName as chatModule} from "../../../ducks/Chat";

const Chat = ({userData}) => (
   <section className="chat-section">
      <div className="wrapper">
         <div className={`chat-container flex j-sb flex-align-center ${userData && 'flex-direction-col'}`}>
            <ChatSignIn/>
            <ChatBox/>
            <ChatLogout/>
         </div>
      </div>
   </section>
);

Chat.propTypes = {
   userData: propTypes.object,
};

export default connect(state => ({
   userData: state[chatModule].userData,
}))(Chat);
