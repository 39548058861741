import { push } from 'connected-react-router';
import { appName } from "../config/config";
import { Auth } from "../config/service";

/**
 * Constants
 */

export const moduleName = 'auth';
const prefix = `${appName}/${moduleName}`;

const CHECK_NUMBER_REQUEST = `${prefix}/CHECK_NUMBER_REQUEST`;
const CHECK_NUMBER_SUCCESS = `${prefix}/CHECK_NUMBER_SUCCESS`;
const CHECK_NUMBER_ERROR = `${prefix}/CHECK_NUMBER_ERROR`;

const LOGIN_REQUEST = `${prefix}/LOGIN_REQUEST`;
const LOGIN_SUCCESS = `${prefix}/LOGIN_SUCCESS`;
const LOGIN_ERROR = `${prefix}/LOGIN_ERROR`;

const RESET_PASSWORD_REQUEST = `${prefix}/RESET_PASSWORD_REQUEST`;
const RESET_PASSWORD_SUCCESS = `${prefix}/RESET_PASSWORD_SUCCESS`;
const RESET_PASSWORD_ERROR = `${prefix}/RESET_PASSWORD_ERROR`;

const initialState = {
   loading: false,
   isMobile: false,
   isResetPass: false,
   loginError: null,
   userData: null,
   checkNumberError: false
};

/**
 * Reducer
 */

export default (state = initialState, action) => {
   switch (action.type) {
      case RESET_PASSWORD_REQUEST:
      case CHECK_NUMBER_REQUEST:
      case LOGIN_REQUEST:
         return {
            ...state,
            loading: true,
            isResetPass: false,
            loginError: null,
            userData: null
         };
      case CHECK_NUMBER_SUCCESS:
         return {
            ...state,
            loading: false,
            checkNumberError: false,
            isResetPass: false,
            loginError: null,
            isMobile: true
         };
      case CHECK_NUMBER_ERROR:
         return {
            ...state,
            loading: false,
            isResetPass: false,
            checkNumberError: true,
            loginError: action.error,
            userData: null,
         };
      case LOGIN_ERROR:
      case RESET_PASSWORD_ERROR:
         return {
            ...state,
            checkNumberError: false,
            loading: false,
            isResetPass: false,
            loginError: action.error,
            userData: null,
         };
      case LOGIN_SUCCESS:
         return {
            ...state,
            checkNumberError: false,
            loading: false,
            isResetPass: false,
            loginError: null,
            userData: action.payload,
         };
      case RESET_PASSWORD_SUCCESS:
         return {
            ...state,
            checkNumberError: false,
            loading: false,
            isResetPass: true,
            loginError: null,
            userData: null
         };
      default:
         return initialState
   }
}

/**
 * Actions
 */

export const checkNumber = userNumber => {
   const request = () => ({type: CHECK_NUMBER_REQUEST});
   const success = () => ({type: CHECK_NUMBER_SUCCESS});
   const failure = error => ({type: CHECK_NUMBER_ERROR, error});

   return dispatch => {
      dispatch(request());
      Auth.checkNumber({phone: userNumber})
         .then(res => (res.data.errors[0].status === 481) && dispatch(success()))
         .catch(err => dispatch(failure(err)));
   };
};

export const login = dataOfUser => {
   const request = () => ({type: LOGIN_REQUEST});
   const success = userData => ({type: LOGIN_SUCCESS, payload: userData});
   const failure = error => ({type: LOGIN_ERROR, error});

   return dispatch => {
      dispatch(request());
      Auth.login(dataOfUser)
         .then(res => {
            if (res.data.meta.user) {
               localStorage.setItem('userSession', res.data.meta.user.session);
               localStorage.setItem('user', JSON.stringify(res.data.meta.user));

               dispatch(success(res.data.meta.user.profile));
               dispatch(push('/'));
            } else if (res.data.errors[0].status === 401) {
               throw res.data.errors[0].status;
            } else if (res.data.errors[0].status === 485) {
               throw res.data.errors[0].status;
            }
         })
         .catch(error => dispatch(failure(error)));
   };
};

export const resetPassword = userNumber => {
   const request = () => ({type: RESET_PASSWORD_REQUEST});
   const success = () => ({type: RESET_PASSWORD_SUCCESS});
   const failure = error => ({type: RESET_PASSWORD_ERROR, error});

   return dispatch => {
      dispatch(request());
      Auth.resetPassword({phone: userNumber})
         .then(res => (res.data.meta.success) && dispatch(success(userNumber)))
         .catch(error => dispatch(failure(error)));
   };
};
