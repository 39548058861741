import {appName} from "../config/config";
import {Auth, socket} from "../config/service";
import {
   EVENT_AUTH,
   EVENT_LASTMSGS,
   EVENT_NEW_MESSAGE,
   EVENT_START_TYPING,
   EVENT_STOP_TYPING,
   EVENT_SUCCESS_AUTHORIZED
} from "./ChatEvents";

/**
 * Constants
 */

export const moduleName = 'chat';
const prefix = `${appName}/${moduleName}`;

const WS_LOGIN_REQUEST = `${prefix}/WS_LOGIN_REQUEST`;
const WS_LOGIN_SUCCESS = `${prefix}/WS_LOGIN_SUCCESS`;
const WS_LOGIN_ERROR = `${prefix}/WS_LOGIN_ERROR`;

const WS_LOGOUT = `${prefix}/WS_LOGOUT`;

const CHECK_NUMBER_TRUE = `${prefix}/CHECK_NUMBER_TRUE`;

const LOAD_ONLINE_USERS = `${prefix}/LOAD_ONLINE_USERS`;
const LOAD_LAST_MESSAGES = `${prefix}/LOAD_LAST_MESSAGES`;
const LOAD_NEW_MESSAGES = `${prefix}/LOAD_NEW_MESSAGES`;

const START_TYPING = `${prefix}/START_TYPING`;
const STOP_TYPING = `${prefix}/STOP_TYPING`;

const SUCCESS_AUTHORIZED = `${prefix}/SUCCESS_AUTHORIZED`;

const initialState = {
   loading: false,
   error: null,
   userNick: null,
   typingUid: null,
   userSession: null,
   onlineUsers: null,
   userData: null,
   checkNumber: false,
   oMessages: []
};

/**
 * Reducer
 */

export default (state = initialState, action) => {
   switch (action.type) {
      case WS_LOGIN_REQUEST:
         return {
            ...state,
            loading: true,
            error: null
         };

      case WS_LOGIN_SUCCESS:
         return {
            ...state,
            loading: false,
            userNick: action.payload.nickname,
            userSession: action.payload.session,
            error: null
         };

      case WS_LOGIN_ERROR:
         return {
            ...state,
            loading: false,
            userSession: null,
            error: action.error
         };

      case CHECK_NUMBER_TRUE:
         return {
            ...state,
            checkNumber: true,
         };

      case WS_LOGOUT:
         return {
            ...state,
            error: null,
            userNick: null,
            typingUid: null,
            userSession: null,
            onlineUsers: null,
            userData: null,
            checkNumber: false
         };

      case START_TYPING:
      case STOP_TYPING:
         return {
            ...state,
            typingUid: action.payload
         };

      case LOAD_ONLINE_USERS:
         return {
            ...state,
            onlineUsers: action.payload
         };

      case SUCCESS_AUTHORIZED:
         return {
            ...state,
            userData: action.payload,
         };

      case LOAD_LAST_MESSAGES:
         return {
            ...state,
            oMessages: [
               ...action.payload.lastMessages,
            ]
         };

      case LOAD_NEW_MESSAGES:
         return {
            ...state,
            oMessages: [
               ...state.oMessages,
               action.payload.newMessage
            ]
         };
      default:
         return state
   }
}

/**
 * Actions
 */

export const wsLogin = dataOfUser => {
   const request = () => ({type: WS_LOGIN_REQUEST});
   const success = ({nickname, session}) => ({type: WS_LOGIN_SUCCESS, payload: {nickname, session}});
   const failure = error => ({type: WS_LOGIN_ERROR, error});

   const handleCheckNumberTrue = () => ({type: CHECK_NUMBER_TRUE});

   return dispatch => {
      dispatch(request());
      Auth.wsLogin(dataOfUser)
         .then(res => {
            if (res.data.errors) {
               dispatch(failure(res.data.errors[0].status))
            } else if (!res.data.data.session && res.data.data.nickname) {
               dispatch(success({nickname: res.data.data.nickname}));
               dispatch(handleCheckNumberTrue());
            } else if (res.data.data.session) {
               localStorage.setItem('wsUserSession', res.data.data.session);
               dispatch(success({nickname: res.data.data.nickname, session: res.data.data.session}));
            }
         })
         .catch(error => dispatch(failure(error)))
   }
};

export const wsLogout = () => {
   localStorage.removeItem('wsUserSession');

   return {
      type: WS_LOGOUT
   }
};

function handleSocketIoEvent(dispatch, actionType, chatEventType) {
   const success = data => ({type: actionType, payload: data});

   switch (chatEventType) {
      case EVENT_LASTMSGS:
         return socket.on(chatEventType, res => dispatch(success({
            lastMessages: JSON.parse(res).reverse()
         })));

      case EVENT_NEW_MESSAGE:
         return socket.on(chatEventType, res => dispatch(success({
            newMessage: JSON.parse(res)
         })));

      case EVENT_START_TYPING:
         return socket.on(chatEventType, res => dispatch(success({
            uid: JSON.parse(res).uid,
            eventType: chatEventType
         })));

      case EVENT_STOP_TYPING:
         return socket.on(chatEventType, res => dispatch(success({
            uid: JSON.parse(res).uid,
            eventType: chatEventType
         })));

      default:
         return socket.on(chatEventType, res =>
            dispatch(success(JSON.parse(res))));
   }
}

// Emit events

export const initUser = (session) => () =>
   session && socket.emit(EVENT_AUTH, {session});

export const sendNewMessage = (message, local_id) => () =>
   socket.emit(EVENT_NEW_MESSAGE, {message, local_id});

export const reqStartTyping = () => () =>
   socket.emit(EVENT_START_TYPING);

export const reqStopTyping = () => () =>
   socket.emit(EVENT_STOP_TYPING);

// Handle events

export const successAuthorized = () => dispatch => handleSocketIoEvent(dispatch, SUCCESS_AUTHORIZED, EVENT_SUCCESS_AUTHORIZED);
export const loadLastMessages = () => dispatch => handleSocketIoEvent(dispatch, LOAD_LAST_MESSAGES, EVENT_LASTMSGS);
export const loadNewMessage = () => dispatch => handleSocketIoEvent(dispatch, LOAD_NEW_MESSAGES, EVENT_NEW_MESSAGE);
export const resStartTyping = () => dispatch => handleSocketIoEvent(dispatch, START_TYPING, EVENT_START_TYPING);
export const resStopTyping = () => dispatch => handleSocketIoEvent(dispatch, STOP_TYPING, EVENT_STOP_TYPING);

// export const loadOnlineUsers = () => dispatch => handleSocketIoEvent(dispatch, LOAD_ONLINE_USERS, EVENT_ONLINE_USERS);
