import React from 'react';
import {Link} from "react-router-dom";
import {FormattedHTMLMessage, FormattedMessage} from 'react-intl';

import Footer from "../Sections/Footer";

const Information = ({isAbout}) => (
   <div className="information-wrapper">
      <div className="information-header">
         <Link className="information-link" to="/">
            <i className="fa fa-long-arrow-left" aria-hidden="true"/>
         </Link>
         <p>
            <FormattedMessage id={isAbout ? 'footerAboutProject' : 'informationTitle'}/>
         </p>
      </div>

      <div className="information-body">
         <FormattedHTMLMessage id={isAbout ? 'aboutDesc' : 'informationDesc'}/>
      </div>

      <Footer/>
   </div>
);

export default Information;
