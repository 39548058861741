import React from 'react';
import {FormattedMessage} from "react-intl";
import {Link} from 'react-router-dom';

import ChangeLang from '../UI/ChangeLang'

const Footer = () => (
   <footer>
      <div className="wrapper footer-box flex j-sb flex-align-center">
         <ul className="flex">
            <li>
               <Link className="text-decor-none" to="/about-project">
                  <FormattedMessage id="footerAboutProject"/>
               </Link>
            </li>
            <li>
               <Link className="text-decor-none" to="/information">
                  <FormattedMessage id="footerAgreement"/>
               </Link>
            </li>
            <li>
               <ChangeLang isFooter={true}/>
            </li>
         </ul>

         <p>&copy; 2017-{ (new Date()).getFullYear() } meet.kz</p>
      </div>
   </footer>
);

export default Footer;