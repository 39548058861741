import React from 'react';

const Spinner = () => (
   <div className="lds-ellipsis">
      <div/>
      <div/>
      <div/>
      <div/>
   </div>
);

export default Spinner;