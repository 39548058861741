export const handleChange = (e, cb) => cb({[e.target.name]: e.target.value});

export const togglePassVisibility = (cb) => cb(({hidden}) => ({
   hidden: !hidden
}));

export const matchPhone = (e) => {
   const reg = /\D*/g;

   if (e.target.value.search(reg) !== -1) {
      e.target.value = e.target.value.replace(reg, '');
   }
};
