import React from 'react';
import ReactDOM from 'react-dom';
import Modal from "react-modal";
import AOS from 'aos';

import './assets/scss/style.scss';
import './assets/css/font-awesome.min.css';
import 'aos/dist/aos.css';

import App from './App';

Modal.setAppElement('#root');
AOS.init();
ReactDOM.render(<App/>, document.getElementById('root'));
