import React, { Component } from 'react';
import ErrorIndicator from './ErrorIndicator';

export default class ErrorBoundary extends Component {
   state = {
      hasError: false,
      errorMessage: null
   };

   static getDerivedStateFromError(error) {
      return { hasError: true, errorMessage: error };
   }
   //
   // componentDidCatch(error, errorInfo) {
   //    // this.setState({ hasError: true })
   //    console.log(error, errorInfo);
   // }

   render() {
      if (this.state.hasError) {
         return <ErrorIndicator errorMessage={this.state.errorMessage} />
      }

      return this.props.children;
   }
}
