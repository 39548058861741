import React from 'react';
import { Route } from 'react-router-dom';

const PrivateRoute = ({component: Component, ...rest}) => (
   <Route {...rest} render={props => (
      localStorage.getItem('userSession')
         ? window.location.href = "/app"
         : <Component {...props} />
   )}/>
);

export default PrivateRoute;
