import React from 'react';
import propTypes from 'prop-types';
import connect from 'react-redux/es/connect/connect';

import {IntlProvider, addLocaleData} from 'react-intl';
import ru from "react-intl/locale-data/ru";
import kk from "react-intl/locale-data/kk";

import {moduleName as localeModule} from "./ducks/Locale";

addLocaleData([...ru, ...kk]);
const LocaleProvider = (props) => {
   const locale = props.locale === 'kz' ? 'kk' : 'ru';

   return (
      <IntlProvider locale={locale} messages={props.messages}>
         {props.children}
      </IntlProvider>
   )
};

LocaleProvider.propTypes = {
   children: propTypes.element.isRequired,
   locale: propTypes.string.isRequired,
   messages: propTypes.object.isRequired,
};

export default connect(state => ({
   locale: state[localeModule].lang,
   key: state[localeModule].lang,
   messages: state[localeModule].messages
}))(LocaleProvider)
