import React, {Component} from 'react';
import propTypes from "prop-types";
import {compose} from "redux";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";

import Spinner from "../../UI/Spinner";

import {
   wsLogin, initUser,
   successAuthorized,

   moduleName as chatModule
} from "../../../ducks/Chat";

import {matchPhone, handleChange, togglePassVisibility} from '../../../config/utils';

class ChatSignIn extends Component {
   static propTypes = {
      checkNumber: propTypes.bool.isRequired,
      loading: propTypes.bool.isRequired,
      userData: propTypes.object,
      userSession: propTypes.any,
      userNick: propTypes.any,
      error: propTypes.any,

      successAuthorized: propTypes.func.isRequired,
      initUser: propTypes.func.isRequired,
      wsLogin: propTypes.func.isRequired,
   };

   state = {
      phone: '7',
      password: '',
      nickname: '',
      hidden: true,
      isValid: true,
   };

   componentDidMount() {
      const session = localStorage.getItem('wsUserSession');

      if (session) {
         this.props.initUser(session);
         this.props.successAuthorized();
      }
   }

   componentDidUpdate(prevProps, prevState) {
      const {userSession, error} = this.props;

      if ((userSession !== prevProps.userSession)) {
         this.props.initUser(userSession);
         this.props.successAuthorized();
      }

      if (error !== prevProps.error) {
         this.setState({password: '', nickname: ''})
      }
   }

   handleSubmit = e => {
      e.preventDefault();

      const {phone, password, nickname} = this.state;
      const {checkNumber} = this.props;

      if (!checkNumber) {
         if (phone.length === 11) {
            this.setState({isValid: true});
            this.props.wsLogin({phone});
         } else {
            this.setState({isValid: false})
         }
      } else if (checkNumber) {
         if (phone.length === 11 && password && nickname) {
            this.setState({isValid: true});
            this.props.wsLogin({phone, password, nickname});
         } else {
            this.setState({isValid: false})
         }
      }
   };

   render() {
      const {phone, password, nickname, hidden, isValid} = this.state;
      const {userData, intl, loading, userNick, error, checkNumber} = this.props;
      const classes = {
         chatSuccess: userData && 'chat-success-width',
      };

      const RenderMessage = () =>
         (loading) ? <Spinner/> :
            (!isValid) ? <span style={{color: 'tomato'}}><FormattedMessage id="invalidData"/></span> :
               (error && error.code === 'ECONNABORTED') ? <span style={{color: 'tomato'}}><FormattedMessage id="timeoutIndicator"/></span> :
                  (error === 500) ? <span style={{color: 'tomato'}}><FormattedMessage id="invalidData"/></span> :
                     (error === 401) ? <span style={{color: 'tomato'}}><FormattedMessage id="chatAuthError"/></span> : '';

      return (
         <div className={`chat-info-box info-position-l ${classes.chatSuccess}`}>
            <h2 className="chat-info__title text-title">
               <FormattedMessage id="chatTitle"/>
            </h2>
            <p className="chat-info__desc text-desc">
               <FormattedMessage id="chatDesc"/>
            </p>

            {!userData && (
               <div className="chat-sign-in">
                  <form onSubmit={this.handleSubmit}>
                     <div className="chat-sign-in__field chat-sign-in__field__phone">
                        <label>
                           <input
                              className="uk-input"
                              type="tel"
                              placeholder="7 (___) __ __ ___"
                              name="phone"
                              value={phone}
                              onChange={e => handleChange(e, this.setState.bind(this))}
                              onInput={matchPhone}
                           />
                        </label>
                     </div>

                     {checkNumber && (
                        <div>
                           <div className="chat-sign-in__field">
                              <label>
                                 <input
                                    className="uk-input"
                                    type="text"
                                    placeholder={intl.formatMessage({id: 'chatNickname'})}
                                    name="nickname"
                                    value={nickname}
                                    onChange={e => handleChange(e, this.setState.bind(this))}
                                 />
                              </label>
                           </div>

                           <div className="chat-sign-in__field">
                              <label>
                                 <input
                                    className="uk-input"
                                    type={hidden ? "password" : "text"}
                                    placeholder={intl.formatMessage({id: 'chatPassword'})}
                                    name="password"
                                    value={password}
                                    onChange={e => handleChange(e, this.setState.bind(this))}
                                 />
                              </label>
                              <button className="hide-show-btn" onClick={() => togglePassVisibility(this.setState.bind(this))} type="button">
                                 {hidden ?
                                    <i className="fa fa-eye" aria-hidden="true"/> :
                                    <i className="fa fa-eye-slash" aria-hidden="true"/>}
                              </button>
                           </div>
                        </div>
                     )}

                     <div className="chat-sign-in__submit">
                        <button className="main-btn" disabled={loading}>
                           <FormattedMessage id="chatJoin"/>
                        </button>
                     </div>

                     <div className="chat-sign-in__notification">
                        <p>
                           {userNick && <span className="text-desc"><FormattedMessage id="chatUsername"/> {userNick}</span>}
                        </p>
                        <p>
                           {!checkNumber && loading &&
                           <span className="text-desc"><FormattedMessage id="chatWaitPass"/> {userNick}</span>}
                        </p>

                        <RenderMessage/>
                     </div>
                  </form>
               </div>
            )}
         </div>
      )
   }
}

export default compose(
   connect(
      state => ({
         userData: state[chatModule].userData,
         userSession: state[chatModule].userSession,
         checkNumber: state[chatModule].checkNumber,
         userNick: state[chatModule].userNick,
         loading: state[chatModule].loading,
         error: state[chatModule].error,
      }),
      {
         wsLogin, initUser,
         successAuthorized
      }
   ),
   injectIntl
)(ChatSignIn);
