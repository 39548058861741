import React from 'react';
import {FormattedMessage} from "react-intl";

import {
   avatar1, avatar2, avatar3,
   avatar4, avatar5, avatar6,
   group2
} from '../../assets/img';

const Communicate = () => (
   <section className="communicate-section">
      <div className="wrapper" data-aos="fade-up" data-aos-duration="600">
         <div className="communicate-overlay-users">
            <img src={avatar1} alt="communicate-user"/>
            <img src={avatar2} alt="communicate-user"/>
            <img src={avatar3} alt="communicate-user"/>
            <img src={avatar4} alt="communicate-user"/>
            <img src={avatar5} alt="communicate-user"/>
            <img src={avatar6} alt="communicate-user"/>
         </div>
         <div className="communicate-box flex j-sb">
            <div className="communicate-box__info info-position-l">
               <h2 className="communicate-box__title text-title">
                  <FormattedMessage id="communicateTitle"/>
               </h2>

               <p className="communicate-box__desc text-desc">
                  <FormattedMessage id="communicateDesc"/>
               </p>
            </div>

            <div className="communicate-box__img info-position-r img-position-adaptive">
               <img src={group2} alt="communicate"/>
            </div>
         </div>
      </div>
   </section>
);

export default Communicate;
