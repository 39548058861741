export const EVENT_AUTH = 'auth';
export const EVENT_ONLINE = 'online';
export const EVENT_OFFLINE = 'offline';
export const EVENT_SUCCESS_AUTHORIZED = 'success_authorized';
export const EVENT_NEW_MESSAGE = 'new_message';
export const EVENT_START_TYPING = 'start_typing';
export const EVENT_STOP_TYPING = 'stop_typing';
export const EVENT_LASTMSGS = 'last_messages';
export const EVENT_ONLINE_USERS = 'online_users';
export const EVENT_REMOVE_MSG = 'remove_message';
export const EVENT_CHAT_OPTIONS = 'chat_options';
export const EVENT_UNBAN = 'unban';
export const ERR_UNAUTHORIZED = 'err_unauthorized';
export const ERR_BANNED = 'err_banned';
export const ERR_REMOVED = 'err_removed';
