import {appName} from "../config/config";

/**
 * Constants
 */

export const moduleName = 'modalTry';
const prefix = `${appName}/${moduleName}`;

const OPEN_MODAL = `${prefix}/OPEN_MODAL`;
const CLOSE_MODAL = `${prefix}/CLOSE_MODAL`;

/**
 * Reducer
 */

const initialState = {
   modalIsOpen: false
};

export default (state = initialState, action) => {
   switch (action.type) {
      case OPEN_MODAL:  return {modalIsOpen: true};
      case CLOSE_MODAL: return {modalIsOpen: false};
      default: return state;
   }
}

/**
 * Action Creators
 */

export const openModal = () => ({type: OPEN_MODAL});
export const closeModal = () => ({type: CLOSE_MODAL});
