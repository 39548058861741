import React, {Component} from 'react';
import propTypes from 'prop-types';
import Modal from "react-modal";
import {FormattedMessage, injectIntl} from 'react-intl';
import {compose} from "redux";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {closeModal, moduleName as modalTryModule} from "../../ducks/ModalTry";

import {
   checkNumber, login, resetPassword,

   moduleName as authModule
} from "../../ducks/Auth";

import {matchPhone, handleChange, togglePassVisibility} from '../../config/utils';

import Spinner from "../UI/Spinner";

const customStyles = {
   content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-45%',
      transform: 'translate(-50%, -50%)',
      borderRadius: '10px',
      textAlign: 'center',
      padding: '0',
      boxSizing: 'border-box'
   }
};

class ModalTry extends Component {
   state = {
      phone: '7',
      password: '',
      hidden: true,
      invalidNum: false,
      checkBoxStatus: false
   };

   checkBoxStatus = () => this.setState(({checkBoxStatus}) => ({
      checkBoxStatus: !checkBoxStatus
   }));

   handleSubmit = (e) => {
      e.preventDefault();
      const {phone} = this.state;

      if (phone.length === 11) {
         this.setState({invalidNum: false});
         this.props.checkNumber(parseInt(phone.trim()));
      } else {
         this.setState({invalidNum: true})
      }
   };

   handleSubmitLogin = (e) => {
      e.preventDefault();
      const {phone, password} = this.state;
      if (phone && password) this.props.login({phone, password});
   };

   handleResetPassword = () => {
      const {phone} = this.state;

      if (phone.length === 11) {
         this.setState({invalidNum: false});
         this.props.resetPassword(phone);
      } else {
         this.setState({invalidNum: true});
      }
   };

   render() {
      const {
         modalIsOpen, closeModal, loading,
         intl, isMobile, loginError,
         isResetPass, checkNumberError,
         userData
      } = this.props;

      const {
         invalidNum, hidden, checkBoxStatus,
         phone, password
      } = this.state;

      const RenderMessage = () =>
         (loading) ? <Spinner/> :
            (isResetPass) ? <span><FormattedMessage id="modalTryResetPass"/></span> :
               (invalidNum) ? <span style={{color: 'tomato'}}><FormattedMessage id="invalidNumber"/></span> :
                  (userData) ? <span style={{color: '#28a745'}}><FormattedMessage id="modalTrySuccessLogin"/></span> :
                     (checkNumberError && loginError && loginError.code === 'ECONNABORTED') ? <span style={{color: 'tomato'}}><FormattedMessage id="timeoutIndicator"/></span> :
                        (loginError && loginError && loginError.code === 'ECONNABORTED') ? <span style={{color: 'tomato'}}><FormattedMessage id="timeoutIndicator"/></span> :
                           (checkNumberError && loginError.response.status === 500) ? <span style={{color: 'tomato'}}><FormattedMessage id="modalTryAuthError"/></span> :
                              (loginError === 401) ? <span style={{color: 'tomato'}}><FormattedMessage id="modalTryAuthError"/></span> :
                                 (loginError === 485) ? <span style={{color: 'tomato'}}><FormattedMessage id="modalTryBanned"/></span> :
                                    (checkNumberError) ? <span style={{color: '#28a745'}}><FormattedMessage id="modalTryRegistered"/></span> : '';

      return (
         <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
         >
            <div className="modal-try">
               <div className="modal-close" onClick={closeModal}>
                  <i className="fa fa-times" aria-hidden="true"/>
               </div>

               <h2 className="text-desc">
                  <FormattedMessage id="modalTryTitle"/>
               </h2>

               <form onSubmit={isMobile ? this.handleSubmitLogin : this.handleSubmit}>
                  <div>
                     <p className="field">
                        <label className="modal-phone">
                           <i className="fa fa-phone" aria-hidden="true"/>
                           <input
                              type="tel"
                              name="phone"
                              placeholder="7 (___) __ __ ___"
                              value={phone}
                              onInput={matchPhone}
                              onChange={e => handleChange(e, this.setState.bind(this))}
                           />
                        </label>
                     </p>
                     {isMobile &&
                     <p className="field">
                        <label className="modal-password">
                           <i className="fa fa-unlock-alt" aria-hidden="true"/>
                           <input
                              type={hidden ? "password" : "text"}
                              name="password"
                              placeholder={intl.formatMessage({id: 'modalTryPassword'})}
                              value={password}
                              onChange={e => handleChange(e, this.setState.bind(this))}
                           />
                        </label>
                        <button className="hide-show-btn" onClick={() => togglePassVisibility(this.setState.bind(this))} type="button">
                           {hidden ?
                              <i className="fa fa-eye" aria-hidden="true"/> :
                              <i className="fa fa-eye-slash" aria-hidden="true"/>}
                        </button>
                     </p>
                     }
                  </div>

                  <div className="agree-box flex flex-align-center j-center">
                     <div className="agree-field">
                        <i
                           className={`fa fa-check-circle${!checkBoxStatus ? '-o' : ''}`}
                           aria-hidden="true"
                           onClick={this.checkBoxStatus}
                        />
                     </div>
                     <p>
                        <FormattedMessage id="modalTryAgreement"/> <Link to="/information"><FormattedMessage
                        id="modalTryAgreementSpan"/></Link>
                     </p>
                  </div>

                  <div className="modal-submit">
                     <button id="modal-submit-btn" className="main-btn transition" disabled={!checkBoxStatus}>
                        <FormattedMessage id={loginError ? "btn_again" : "btn_next"}/>
                     </button>
                  </div>

                  <div className="modal-notification">
                     <RenderMessage/>
                  </div>

                  <div className="forgot-pass">
                     <button onClick={this.handleResetPassword} type="button">
                        <FormattedMessage id="forgotPassword"/>
                     </button>
                  </div>
               </form>
            </div>
         </Modal>
      )
   }
}

ModalTry.propTypes = {
   modalIsOpen: propTypes.bool.isRequired,
   isMobile: propTypes.bool.isRequired,
   loading: propTypes.bool,
   isResetPass: propTypes.bool,
   checkNumberError: propTypes.bool,
   userData: propTypes.object,
   loginError: propTypes.any,

   closeModal: propTypes.func.isRequired,
   checkNumber: propTypes.func.isRequired,
   resetPassword: propTypes.func.isRequired,
   login: propTypes.func.isRequired,
};

export default compose(
   connect(state => ({
      modalIsOpen: state[modalTryModule].modalIsOpen,
      isMobile: state[authModule].isMobile,
      isResetPass: state[authModule].isResetPass,
      checkNumberError: state[authModule].checkNumberError,
      loginError: state[authModule].loginError,
      userData: state[authModule].userData,
      loading: state[authModule].loading,
   }), {
      closeModal, checkNumber, login, resetPassword
   }),
   injectIntl
)(ModalTry);
