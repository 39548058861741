import React, {Component} from 'react';
import propTypes from 'prop-types';
import {connect} from "react-redux";
import {compose} from "redux";
import {injectIntl} from "react-intl";

import {
   reqStartTyping, reqStopTyping,
   resStartTyping, resStopTyping,
   sendNewMessage,

   moduleName as chatModule
} from "../../../ducks/Chat";

import {handleChange} from '../../../config/utils'

class ChatSendMessage extends Component {
   static propTypes = {
      userData: propTypes.object,

      reqStartTyping: propTypes.func.isRequired,
      reqStopTyping: propTypes.func.isRequired,
      resStartTyping: propTypes.func.isRequired,
      resStopTyping: propTypes.func.isRequired,
      sendNewMessage: propTypes.func.isRequired,
   };

   constructor(props) {
      super(props);

      this.state = {
         messageInput: ''
      };

      this.textInput = React.createRef();
   }

   componentDidMount() {
      this.props.resStartTyping();
      this.props.resStopTyping();
   }

   componentDidUpdate(prevProps, prevState) {
      this.textInput.current && this.textInput.current.focus();
   }

   handleSubmit = e => {
      e.preventDefault();

      const {messageInput} = this.state;
      const date = new Date().getTime();

      if (messageInput.length !== 0) {
         this.props.sendNewMessage(messageInput, date.toString());
         this.props.reqStopTyping();
         this.setState({messageInput: ''});
      }
   };

   startTyping = e => {
      e.target.value.length !== 0 ?
         this.props.reqStartTyping() :
         this.props.reqStopTyping();
   };

   render() {
      const {userData, intl} = this.props;
      const {messageInput} = this.state;

      return userData && (
         <div className="chat-container-send">
            <form onSubmit={this.handleSubmit}>
               <input
                  id="chat-box-send"
                  className="chat-box-send"
                  type="text"
                  ref={this.textInput}
                  name="messageInput"
                  value={messageInput}
                  placeholder={intl.formatMessage({id: 'chatSendMessage'})}
                  onChange={e => handleChange(e, this.setState.bind(this))}
                  onInput={this.startTyping}
               />
               <button className="chat-box-send-btn transition">
                  <i className="fa fa-envelope" aria-hidden="true"/>
               </button>
            </form>
         </div>
      );
   }
}

export default compose(
   connect(
      state => ({
         userData: state[chatModule].userData,
      }),
      {
         reqStartTyping, reqStopTyping,
         resStartTyping, resStopTyping,
         sendNewMessage
      }
   ),
   injectIntl
)(ChatSendMessage);
