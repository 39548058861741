import React from 'react';
import propTypes from "prop-types";
import {connect} from 'react-redux';
import {FormattedHTMLMessage, FormattedMessage} from "react-intl";

import {openModal} from "../../ducks/ModalTry";

const Stock = ({openModal}) => (
   <div className="stock" data-aos="zoom-out-up" data-aos-duration="600">
      <div className="stock-box">
         <p className="text-desc">
            <FormattedHTMLMessage id="stockDesc"/>
         </p>

         <button className="stock-btn main-btn transition" onClick={openModal}>
            <FormattedMessage id="btn_try"/>
         </button>
      </div>
   </div>
);

Stock.propTypes = {
   openModal: propTypes.func.isRequired
};

export default connect(null, {openModal})(Stock);
