import {appName} from "../config/config";
import {KZ_TRANSLATION} from "../i18n/kz";
import {RU_TRANSLATION} from "../i18n/ru";

/**
 * Constants
 */

export const moduleName = 'locale';
const prefix = `${appName}/${moduleName}`;

const CHANGE_LANG = `${prefix}/CHANGE_LANG`;

/**
 * Reducer
 */

const initialState = {
   lang: RU_TRANSLATION.lang,
   messages: RU_TRANSLATION.messages
};

export default (state = initialState, action) => {
   const {type, lang} = action;

   if (type === CHANGE_LANG) {
      return getLocale(lang);
   } else {
      return getLocale(lang);
   }
}

/**
 * Action Creators
 */

export const changeLang = lang => ({
   type: CHANGE_LANG,
   lang
});

function getLocale(oldLang) {
   const lang = getLang(oldLang);

   switch (lang) {
      case 'ru':
         return {
            lang: RU_TRANSLATION.lang,
            messages: RU_TRANSLATION.messages
         };
      case 'kz':
         return {
            lang: KZ_TRANSLATION.lang,
            messages: KZ_TRANSLATION.messages
         };
      default:
         return initialState;
   }
}

function getLang(lang) {
   if (!lang) lang = localStorage.getItem('lang');
   if (!lang) lang = initialState.lang;

   localStorage.setItem('lang', lang);
   return lang;
}
